export const QUERY_PARAMS = {
    POST_SLUG: 'beitrag',
}
export const SCROLLCONFIG = {
    SCROLL_EASING: 'ease-out',
    SCROLL_OFFSET: -64,
    SCROLL_DURATION: 300,
}
export const POST_TYPES = ['post']
export const ICONS_PACK_URL = 'https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css'

// API
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm:ss'
export const WP_API = `/wp-json/wp/v2`
export const WP_API_MENUS = `/wp-json/wp-api-menus/v2`
export const WP_API_JWT = `/wp-json/jwt-auth/v1`
export const WP_API_CUSTOM = `/wp-json/p8app/v1`
export const DEFAULT_POST_TYPE = 'pages'
export const API_ENDPOINTS = {
    GET_PAGE_BY_ID: (pageId) => `${WP_API}/pages/${pageId}`,
    GET_PAGE_BY_SLUG: (slug, postType, lang) => `${WP_API}/${postType}?slug=${slug}&lang=${lang}`,
    GET_PAGE_BY_PATH: (path) => `${WP_API}/page/${path}`,
    GET_PAGE_BY_CATEGORY: (slug, lang) => `${WP_API}/categories?slug=${slug}&lang=${lang}`,
    GET_HEADER_MENU: (id) => `${WP_API_MENUS}/menus/${id}`,
    GET_FOOTER_MENU: (id) => `${WP_API_MENUS}/menus/${id}`,
    LOGIN: `${WP_API_JWT}/token`,
    CHECKTOKEN: `${WP_API_JWT}/token/validate`,
    ADD_CONTACT_FORM: `/kontakt-danke`,
    GET_I18N: (lang) => `${lang}${WP_API_CUSTOM}/locale/i18n`,
    SEND_SMS: `${WP_API_CUSTOM}/user/sendsms/`,
    GET_NEWS: (filters) => `${WP_API_CUSTOM}/news/filter?${filters}`,
    GET_TRAININGS: (filters) => `${WP_API_CUSTOM}/trainings/filter?${filters}`,
    CHECK_AUTH_CODE: `${WP_API_CUSTOM}/user/checkauthcode/`,
    CHECK_SIGNUP: (id) => `${WP_API_CUSTOM}/user/checksignup/${id}`,
    REGISTER_PHONE: (id) => `${WP_API_CUSTOM}/user/registerphone/${id}`,
    SEND_FIRSTCODE: (id) => `${WP_API_CUSTOM}/user/sendfirstcode/${id}`,
    REQUEST_ACCESS: `${WP_API_CUSTOM}/user/requestaccess/`,
    RESET_PASSWORD: `${WP_API_CUSTOM}/user/resetpassword/`,
    UPDATE_USER: `${WP_API_CUSTOM}/user/updateuser/`,
    UPLOAD_IMAGE: `${WP_API_CUSTOM}/utils/uploadimage`,
    SET_PROFILE_IMAGE: `${WP_API_CUSTOM}/user/setprofileimage`,
    ADD_LIKE: (id) => `${WP_API_CUSTOM}/news/addlike/${id}`,
    ADD_SAVE: (id) => `${WP_API_CUSTOM}/news/addsaves/${id}`,
    DELETE_MESSAGE: `${WP_API_CUSTOM}/messages/remove/`,
    READ_MESSAGE: `${WP_API_CUSTOM}/messages/read/`,
    SET_COMMENT: `${WP_API}/comments`,
    DELETE_COMMENT: (id) => `${WP_API_CUSTOM}/news/deletecomment/${id}`,
    GET_PEOPLE: (filters) => `${WP_API_CUSTOM}/user/filter?${filters}`,
    UPDATE_LASTCONTACTS: `${WP_API_CUSTOM}/user/lastcontact/`,
    EVENT_REGISTRATION: `${WP_API_CUSTOM}/user/eventregistration/`,
    SET_ONBOARDING_STATUS: (status) => `${WP_API_CUSTOM}/user/setonboardingstatus?status=${status}`,
    CREATE_EVENT: `${WP_API_CUSTOM}/calendar/create-event/`,
    GET_EVENT_ICS: (querySelector) => `${WP_API_CUSTOM}/eventics?${querySelector}`,
}

// MAIN CONFIG
export const CONFIG = {
    BASE_URL: 'https://app.gswb.p8.eu',
    BASE: '/',

    TITLE: 'gswb App',
    META_DESCRIPTION: '',
    SHORTNAME: 'gswb App',
    // Generate directory
    GENERATE_DIR: 'www',
    ICONS_PATH: 'icons',
    ICON: 'icon.png',
}
