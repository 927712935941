import Vue from 'vue'
import axios from 'axios'
import { API_ENDPOINTS, DEFAULT_POST_TYPE, CONFIG } from '../../constants'

const axiosInstance = axios.create({
    baseURL: CONFIG.BASE_URL,
})

const buildApiURL = (url) => {
    //if (route.query.preview_id && route.query.preview_nonce) url += ["?", "&"][+url.includes("?")] + "autosavepreview=1"

    return url
}

axiosInstance.interceptors.request.use((config) => {
    //console.log(config)

    // Don't send Authorization header
    if (config._ignoreAuthorization) return config
    if (config._ignoreAuthorizationIfNotLogin) {
        if (!Vue.auth.token()) return config
    }

    // console.log(Vue.auth.token())

    config.headers.Authorization = 'Bearer ' + Vue.auth.token()
    return config
})

export const $api = {
    // GET: Page -> Get page by id
    getPageById: (pageId) => {
        return axiosInstance
            .get(buildApiURL(API_ENDPOINTS.GET_PAGE_BY_ID(pageId)), {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: Page -> Get page by slug
    getPageBySlug: (slug, postType = DEFAULT_POST_TYPE, locale) => {
        return axiosInstance
            .get(buildApiURL(API_ENDPOINTS.GET_PAGE_BY_SLUG(slug, postType, locale)), {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: Page -> Get page by path
    getPageByPath: (path) => {
        return axiosInstance
            .get(buildApiURL(API_ENDPOINTS.GET_PAGE_BY_PATH(path)), {
                _ignoreAuthorizationIfNotLogin: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: Page -> Get page by category slug
    getPageByCategory: (slug, locale) => {
        return axiosInstance
            .get(buildApiURL(API_ENDPOINTS.GET_PAGE_BY_CATEGORY(slug, locale)), {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: Menu -> Navigation
    getHeaderMenu: (id) => {
        return axiosInstance
            .get(API_ENDPOINTS.GET_HEADER_MENU(id), {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: Menu -> Footer
    getFooterMenu: (id) => {
        return axiosInstance
            .get(API_ENDPOINTS.GET_FOOTER_MENU(id), {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: i18n Strings
    getI18nData: (lang) => {
        var prefix = ''
        if (lang && lang != 'de') prefix = '/' + lang

        return axiosInstance
            .get(API_ENDPOINTS.GET_I18N(prefix), {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: Contact -> send contact form
    addContactForm: (data) => {
        return axiosInstance.post(API_ENDPOINTS.ADD_CONTACT_FORM, data).then((response) => response.data)
    },
    // POST password reset
    sendPasswordReset: (email) => {
        return axiosInstance
            .post(
                API_ENDPOINTS.RESET_PASSWORD,
                {
                    email: email,
                },
                {
                    _ignoreAuthorization: true,
                }
            )
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: sendsms
    sendsms: (tel) => {
        return axiosInstance
            .post(
                API_ENDPOINTS.SEND_SMS,
                {
                    tel: tel,
                },
                {
                    _ignoreAuthorization: true,
                }
            )
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: checkauthcode
    checkauthcode: (code) => {
        return axiosInstance
            .post(
                API_ENDPOINTS.CHECK_AUTH_CODE,
                {
                    code: code,
                },
                {
                    _ignoreAuthorization: true,
                }
            )
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: checksignup
    checksignup: (id, credentials) => {
        return axiosInstance
            .post(API_ENDPOINTS.CHECK_SIGNUP(id), credentials, {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: sendfirstcode
    sendfirstcode: (id, tel) => {
        return axiosInstance
            .post(
                API_ENDPOINTS.SEND_FIRSTCODE(id),
                {
                    tel: tel,
                },
                {
                    _ignoreAuthorization: true,
                }
            )
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: registerphone
    registerphone: (id, credentials) => {
        return axiosInstance
            .post(API_ENDPOINTS.REGISTER_PHONE(id), credentials, {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: registerphone
    updateuser: (credentials) => {
        return axiosInstance
            .post(API_ENDPOINTS.UPDATE_USER, credentials)
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: Login
    login: (username, password) => {
        return axiosInstance
            .post(
                API_ENDPOINTS.LOGIN,
                {
                    username: username,
                    password: password,
                },
                {
                    _ignoreAuthorization: true,
                }
            )
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    checkToken: () => {
        return axiosInstance
            .post(API_ENDPOINTS.CHECKTOKEN)
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: request an access for the app
    requestAccess: (data) => {
        return axiosInstance
            .post(API_ENDPOINTS.REQUEST_ACCESS, data, {
                _ignoreAuthorization: true,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: News with filter parameters
    getNews: (filters) => {
        return axiosInstance
            .get(API_ENDPOINTS.GET_NEWS(filters))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // GET: Trainings with filter parameters
    getTrainings: (filters) => {
        return axiosInstance
            .get(API_ENDPOINTS.GET_TRAININGS(filters))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: upload an image to attachments
    uploadImage: (image) => {
        return axiosInstance
            .post(API_ENDPOINTS.UPLOAD_IMAGE, image)
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: set user's onboarding status
    setOnboardingStatus: (status) => {
        // js boolean to api param
        const validStatus = status ? '1' : '0'
        return axiosInstance
            .post(API_ENDPOINTS.SET_ONBOARDING_STATUS(validStatus))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: set profile image by attachment id
    setProfileImage: (attachmentId) => {
        return axiosInstance
            .post(API_ENDPOINTS.SET_PROFILE_IMAGE, {
                id: attachmentId,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: Add Like
    addlike: (post_id) => {
        return axiosInstance
            .post(API_ENDPOINTS.ADD_LIKE(post_id))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // DELETE: DELETE Like
    deletelike: (post_id) => {
        return axiosInstance
            .delete(API_ENDPOINTS.ADD_LIKE(post_id))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // POST: Add Save
    addsave: (post_id) => {
        return axiosInstance
            .post(API_ENDPOINTS.ADD_SAVE(post_id))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
    // DELETE: DELETE Like
    deletesave: (post_id) => {
        return axiosInstance
            .delete(API_ENDPOINTS.ADD_SAVE(post_id))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: DELETE Message
    deletemessage: (post_id) => {
        return axiosInstance
            .post(API_ENDPOINTS.DELETE_MESSAGE, {
                message_id: post_id,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: READ Message
    readmessage: (post_id) => {
        return axiosInstance
            .post(API_ENDPOINTS.READ_MESSAGE, {
                message_id: post_id,
            })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: Set Comment
    setComment: (post_id, comment, parent = false) => {
        let credentials = {
            post: post_id,
            content: comment,
        }

        if (parent) credentials.parent = parent

        return axiosInstance
            .post(API_ENDPOINTS.SET_COMMENT, credentials)
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // DELETE: DELETE Comment
    deleteComment: (post_id) => {
        return axiosInstance
            .delete(API_ENDPOINTS.DELETE_COMMENT(post_id))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // GET: get filtered peaople
    getPeople: (filters) => {
        return axiosInstance
            .get(API_ENDPOINTS.GET_PEOPLE(filters))
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: DELETE Message
    updateLastContacts: (credentials) => {
        return axiosInstance
            .post(API_ENDPOINTS.UPDATE_LASTCONTACTS, credentials)
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: DELETE Message
    eventRegistriation: (id) => {
        return axiosInstance
            .post(API_ENDPOINTS.EVENT_REGISTRATION, { id: id })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },

    // POST: create calendar event
    createEvent: (event, contact) => {
        return axiosInstance
            .post(API_ENDPOINTS.CREATE_EVENT, { event: event, contact: contact })
            .then((response) => response.data)
            .catch(handleApiErrors)
    },
}

// ERROR HANDLER: Process all api erorrs
const handleApiErrors = (err) => {
    console.log(err)
    // Bugfix Invalid Token
    if (err.config && err.config.url == '/wp-json/wp/v2/page/login' && err.response.status == 403) {
        let user = {}
        Vue.auth.unremember()
        Vue.auth.user(user)
        Vue.auth.logout()

        location.reload()
    }

    console.log('Fail')
    return err.response
}

export default {
    install(Vue) {
        Vue.prototype.$api = $api
    },
}
